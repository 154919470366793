/* For use in src/lib/core/theming/_palette.scss */
$pallet: (50 : #e8f0f8,
  100 : #c5daee,
  200 : #9ec2e3,
  300 : #77aad7,
  400 : #5a97cf,
  500 : #000,
  600 : #377dc0,
  700 : #2f72b9,
  800 : #2768b1,
  900 : #1a55a4,
  A100 : #dae9ff,
  A200 : #a7caff,
  A400 : #74abff,
  A700 : #5b9cff,
  contrast: (50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  ));
/* For use in src/lib/core/theming/_palette.scss */
$pallet-accent: (50 : #e4fbf0,
  100 : #bbf5d9,
  200 : #8eeec0,
  300 : #61e7a6,
  400 : #3fe293,
  500 : #1ddd80,
  600 : #1ad978,
  700 : #15d46d,
  800 : #11cf63,
  900 : #0ac750,
  A100 : #f1fff6,
  A200 : #beffd3,
  A400 : #8bffb1,
  A700 : #72ffa0,
  contrast: (50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #000000,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  ));